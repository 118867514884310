export function useNavigationMenu () {
    const state = useGlobalState();
    const features = useFeatureFlag();

    state.navigationMenu.value = [
        {
            label: 'Users',
            to: '/users',
            icon: 'users',
            separator: false
        },
        {
            label: 'Bookings',
            to: '/bookings',
            icon: 'bookings',
            separator: false
        },
        {
            label: 'Services',
            to: '/services',
            icon: 'services',
            separator: false
        },
        {
            label: 'Areas',
            to: '/areas',
            icon: 'area',
            separator: false
        },
        features.accounts
            ? {
                label: 'Accounts',
                to: '/accounts',
                icon: 'accounts',
                separator: false
            }
            : false,
        {
            label: 'Categories',
            to: '/categories',
            icon: 'categories',
            separator: false
        },
        {
            label: 'Modalities',
            to: '/modalities',
            icon: 'modalities',
            separator: false
        },
        {
            label: 'Skills',
            to: '/skills',
            icon: 'toolbox',
            separator: false
        },
        {
            label: 'Payments',
            to: '/payments',
            icon: 'credit-card',
            separator: false
        },
        {
            label: 'Payouts',
            to: '/payouts',
            icon: 'money-check-dollar',
            separator: false
        },
        {
            label: 'Charges',
            to: '/charges',
            icon: 'dollar-sign',
            separator: false
        },
    ].filter(Boolean) as TNavigationMenuItem[];
}
