import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAutoComplete, LazyCalendar, LazyCheckbox, LazyChips, LazyDropdown, LazyInputGroup, LazyInputMask, LazyInputNumber, LazyInputText, LazyMultiSelect, LazyPassword, LazySelectButton, LazyTextarea, LazyToggleButton, LazyButton, LazySplitButton, LazyColumn, LazyDataTable, LazyToolbar, LazyConfirmDialog, LazyDialog, LazyOverlayPanel, LazySidebar, LazyMenu, LazyMessage, LazyToast, LazyProgressSpinner, LazyTag } from '#components'
const lazyGlobalComponents = [
  ["AutoComplete", LazyAutoComplete],
["Calendar", LazyCalendar],
["Checkbox", LazyCheckbox],
["Chips", LazyChips],
["Dropdown", LazyDropdown],
["InputGroup", LazyInputGroup],
["InputMask", LazyInputMask],
["InputNumber", LazyInputNumber],
["InputText", LazyInputText],
["MultiSelect", LazyMultiSelect],
["Password", LazyPassword],
["SelectButton", LazySelectButton],
["Textarea", LazyTextarea],
["ToggleButton", LazyToggleButton],
["Button", LazyButton],
["SplitButton", LazySplitButton],
["Column", LazyColumn],
["DataTable", LazyDataTable],
["Toolbar", LazyToolbar],
["ConfirmDialog", LazyConfirmDialog],
["Dialog", LazyDialog],
["OverlayPanel", LazyOverlayPanel],
["Sidebar", LazySidebar],
["Menu", LazyMenu],
["Message", LazyMessage],
["Toast", LazyToast],
["ProgressSpinner", LazyProgressSpinner],
["Tag", LazyTag],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
